import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useRef } from 'react'

import PageHead from 'components/head/PageHead'
import PageWrapper from 'components/layout/page-wrapper'
import { FeaturesSection, FormSection, HeaderSection } from 'components/pages/exodus-pro'
import { useDimensions, useRellax } from 'js/utils/hooks'
import defaultData from 'data/pages/Contents/support-exodus-pro.json'

import 'static/exodus-pro/scss/styles.scss'

const page = 'support-exodus-pro'

function ExodusProPage({ pageName = page, pageData = defaultData }) {
  // get page data.
  const { content } = pageData
  const { header, features, waitlist } = content

  const backgroundRef = useRef(null)

  const { width } = useDimensions()

  useRellax({ ref: backgroundRef, center: false })

  // get images.
  const images = useStaticQuery(graphql`
    query {
      background800: file(relativePath: { regex: "/^exodus-pro\/img\/header-bg-ssize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 800, placeholder: BLURRED) }
      }
      background1600: file(relativePath: { regex: "/^exodus-pro\/img\/header-bg-lsize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      devices: file(relativePath: { regex: "/^exodus-pro\/img\/devices\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1080, placeholder: BLURRED) }
      }

      featuresChrome: file(relativePath: { regex: "/^exodus-pro\/img\/planet-outline\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
    }
  `)

  const getBackgroundImage = () => {
    if (width && width <= 800) {
      return images.background800.childImageSharp.gatsbyImageData
    } else {
      return images.background1600.childImageSharp.gatsbyImageData
    }
  }

  return (
    <PageWrapper className="x__exodus-pro-page">
      <div
        ref={backgroundRef}
        className="x__exodus-pro-page__background-container"
        data-rellax-speed="-4"
        role="presentation"
      >
        <div className="x__exodus-pro-page__background">
          <span>
            <span>EXODUS </span>PRO
          </span>
          <GatsbyImage image={getBackgroundImage()} alt={header.alt} loading="eager" />
        </div>
      </div>
      {!!header && (
        <HeaderSection data={header} image={images.devices.childImageSharp.gatsbyImageData} />
      )}
      {!!features && (
        <FeaturesSection
          data={features}
          image={images.featuresChrome.childImageSharp.gatsbyImageData}
        />
      )}
      {!!waitlist && <FormSection data={waitlist} />}
    </PageWrapper>
  )
}

export default ExodusProPage

// head and meta data.
export const Head = () => <PageHead page={page} noindex />
